const english = {
	metalbook: 'Metalbook',
	company: 'Metalbook',
	admin: 'Admin',
	trade: 'Trade',

	aadhaar: 'Aadhaar',
	activation: 'Activation',
	all: 'All',
	accept: 'Accept',
	accepted: 'Accepted',
	action: 'Action',
	actions: 'Actions',
	active: 'Active',
	add: 'Add',
	added: 'Added',
	additional: 'Additional',
	address: 'Address',
	adhoc: 'ADHOC',
	admins: 'Admins',
	advance: 'Advance',
	advantage: 'Advantage',
	again: 'Again',
	ageing: 'Ageing',
	allotted: 'Alloted',
	alerts: 'Alerts',
	amount: 'Amount',
	analytics: 'Analytics',
	and: 'and',
	angles: 'MS ANGLES',
	answer: 'Answer',
	any: 'ANY',
	apply: 'apply',
	applications: 'Applications',
	approved: 'Approved',
	assign: 'Assign',
	assigned: 'Assigned',
	attributes: 'Attributes',
	auction: 'Auction',
	author: 'Author',
	authors: 'Authors',
	available: 'Available',
	average: 'Average',
	back: 'Back',
	batch: 'Batch',
	banner: 'Banner',
	bank: 'Bank',
	balance: 'Balance',
	base: 'BASE',
	basic: 'Basic',
	bid: 'Bid',
	bill: 'Bill',
	bills: 'Bills',
	billing: 'Billing',
	bilti: 'Lorry Receipt',
	blog: 'Blog',
	blogs: 'Blogs',
	bpo: 'BPO',
	brand: 'Brand',
	brands: 'Brands',
	bsa: 'BSA',
	bspl: 'BSPL',
	buyer: 'Buyer',
	business: 'Business',
	by: 'By',
	calculation: 'Calculation',
	capacity: 'Capacity',
	cancel: 'Cancel',
	cancelled: 'Cancelled',
	catalog: 'Catalog',
	category: 'Category',
	categories: 'Categories',
	cast: 'Cast',
	calculate: 'Calculate',
	calculator: 'Calculator',
	cases: 'Cases',
	certificate: 'Certificate',
	change: 'Change',
	channel: 'MS Channel',
	charge: 'Charge',
	charges: 'Charges',
	check: 'Check',
	cheque: 'Cheque',
	choose: 'Choose',
	city: 'City',
	clear: 'Clear',
	client: 'Client',
	close: 'Close',
	closed: 'Closed',
	closing: 'Closing',
	closure: 'Closure',
	cloud: 'Cloud',
	cm: 'CM',
	count: 'Count',
	country: 'Country',
	collection: 'Collection',
	collections: 'Collections',
	companies: 'Companies',
	complete: 'Complete',
	completed: 'Completed',
	commitment: 'Commitment',
	commodity: 'Commodity',
	comments: 'Comments',
	confirm: 'Confirm',
	contact: 'Contact',
	contact_us: 'Contact Us',
	continue: 'Continue',
	content: 'Content',
	consent: 'Consent',
	convert: 'Convert',
	conversion: 'Conversion',
	court: 'Court',
	current: 'Current',
	customer: 'Customer',
	customisations: 'Customisations',
	customise: 'Customise',
	cran: 'CRAN',
	create: 'Create',
	created: 'Created',
	credit: 'Credit',
	cross: 'Cross',
	crores: 'Crores',
	cycle: 'Cycle',
	dashboard: 'Dashboard',
	data: 'Data',
	date: 'Date',
	days: 'Days',
	debit: 'Debit',
	defaults: 'Defaults',
	delay: 'Delay',
	delete: 'Delete',
	deleted: 'Deleted',
	delivered: 'Delivered',
	delivery: 'Delivery',
	department: 'Department',
	deposit: 'Deposit',
	deposits: 'Deposits',
	description: 'Description',
	details: 'Details',
	destination: 'Destination',
	diameter: 'Diameter',
	director: 'Director',
	directors: 'Directors',
	directorships: 'Directorships',
	distance: 'Distance',
	district: 'District',
	dispatch: 'Dispatch',
	dispatched: 'Dispatched',
	document: 'Document',
	documents: 'Documents',
	done: 'Done',
	download: 'Download',
	due: 'Due',
	driver: 'Driver',
	edit: 'Edit',
	electricity: 'Electricity',
	email: 'Email',
	email_id: 'Email ID',
	employee: 'Employee',
	end: 'End',
	endorse: 'Endorse',
	endorsement: 'Endorsement',
	enhancement: 'ENHANCEMENT',
	enter: 'Enter',
	enquiry: 'Enquiry',
	enquiries: 'Enquiries',
	entry: 'Entry',
	e_statement: 'E-Statement',
	estimate: 'Estimate',
	errors: 'Errors',
	event: 'Event',
	excel: 'Excel',
	expected: 'Expected',
	expiry: 'Expiry',
	export: 'Export',
	excluding: 'Excl',
	ewaybill: 'E-way Bill',
	fabricator: 'Fabricator',
	featured: 'Featured',
	feedback: 'Feedback',
	fetch: 'Fetch',
	file: 'File',
	final: 'Final',
	finance: 'Finance',
	financial: 'Financial',
	financials: 'Financials',
	first: 'First',
	for: 'FOR',
	form: 'Form',
	formatted: 'Formatted',
	former: 'Former',
	from: 'From',
	freight: 'Freight',
	fsa: 'FSA',
	given: 'Given',
	go: 'Go',
	get: 'Get',
	generate: 'Generate',
	glossary: 'Glossary',
	gm: 'GM(%)',
	gmv: 'GMV(Crs)',
	group: 'Group',
	grade: 'GRADE',
	gross: 'Gross',
	gst: 'GST',
	gstin: 'GSTIN',
	gstr1: 'GSTR - 1',
	gstr2a: 'GSTR - 2A',
	gstr3b: 'GSTR - 3B',
	GST_18: 'GST (18%)',
	hash: 'Hash',
	head: 'Head',
	heading: 'Heading',
	headline: 'Headline',
	height: 'Height',
	highlights: 'Highlights',
	hsn: 'HSN',
	id: 'ID',
	ids: 'IDs',
	ifc: 'IFC',
	ifsc: 'IFSC',
	igst: 'IGST',
	image: 'Image',
	images: 'Images',
	inactive: 'Inactive',
	including: 'Incl',
	incorporation: 'Incorporation',
	incoming: 'Incoming',
	industry: 'Industry',
	info: 'Info',
	input: 'Input',
	inventory: 'Inventory',
	invitee: 'Invitee',
	invitees: 'Invitees',
	invoice: 'Invoice',
	invoices: 'Invoices',
	interest: 'Interest',
	internal: 'Internal',
	intransit: 'In Transit',
	item: 'Item',
	items: 'Items',
	itemwise: 'Item-wise',
	is: 'Is',
	isflag: 'is Red Flag?',
	issue: 'Issue',
	keyword: 'Keyword',
	km: 'KM',
	kyc: 'KYC',
	journey: 'Journey',
	journeys: 'Journeys',
	language: 'Language',
	latest: 'Latest',
	last: 'Last',
	lc: 'LC',
	lead: 'Lead',
	leads: 'Leads',
	ledger: 'Ledger',
	ledgers: 'Ledgers',
	length: 'Length',
	lifetime: 'Lifetime',
	limit: 'Limit',
	list: 'List',
	listing: 'Listing',
	listings: 'Listings',
	live: 'Live',
	loaded: 'Loaded',
	location: 'Location',
	login: 'Login',
	logistic: 'Logistic',
	logistics: 'Logistics',
	logout: 'Logout',
	lumpsum: 'LUMPSUM',
	max: 'Maximum',
	machine: 'Machine',
	machines: 'Machines',
	make: 'Make',
	manage: 'Manage',
	management: 'Management',
	manufacturer: 'Manufacturer',
	material: 'Material',
	materials: 'Materials',
	mark: 'Mark',
	margin: 'Margin',
	map: 'Map',
	mbook: 'Mbook',
	mca: 'MCA',
	meeting: 'Meeting',
	message: 'Message',
	merge: 'Merge',
	mgw: 'MGW',
	mgw_full: 'Minimum Guaranteed Weight',
	mis: 'MIS',
	mismatch: 'Mismatch',
	missing: 'Missing',
	min: 'Minimum',
	mobile: 'Mobile',
	mode: 'Mode',
	month: 'Month',
	monthly: 'Monthly',
	monthwise: 'Month-wise',
	moqq: 'MOQ',
	moq: 'MOQ (Minimum Order Quantity)',
	mm: 'mm',
	mt: 'MT',
	my: 'My',
	name: 'Name',
	new: 'New',
	news: 'News',
	next: 'Next',
	no: 'No',
	noc: 'NOC',
	note: 'Note',
	notes: 'Notes',
	number: 'Number',
	office: 'Office',
	ongoing: 'Ongoing',
	open: 'Open',
	opening: 'Opening',
	order: 'Order',
	orders: 'Orders',
	organic: 'Organic',
	organisation: 'Organisation',
	organisations: 'Organisations',
	origin: 'Origin',
	other: 'Other',
	otp: 'OTP',
	outgoing: 'Outgoing',
	overall: 'Overall',
	owner: 'Owner',
	owned: 'Owned',
	pan: 'PAN',
	partial: 'Partial',
	passport: 'Passport',
	password: 'Password',
	particulars: 'Particulars',
	payables: 'Payables',
	payment: 'Payment',
	payments: 'Payments',
	pdc: 'PDC',
	permanent: 'Permanent',
	pending: 'Pending',
	pending_quantity: 'Pending Quantity ( in MT )',
	per: 'Per',
	percentage: 'Percentage',
	phone: 'Phone',
	pickup: 'Pickup',
	pincode: 'Pincode',
	platform: 'Platform',
	po: 'PO',
	poc: 'POC',
	primary: 'Primary',
	priority: 'Priority',
	product: 'Product',
	profile: 'Profile',
	process: 'Process',
	price: 'Price',
	prices: 'Prices',
	purchase: 'Purchase',
	qty: 'Quantity',
	question: 'Question',
	quote: 'Quote',
	quotes: 'Quotes',
	quotation: 'Quotation',
	quotations: 'Quotations',
	rate: 'Rate',
	ratio: 'Ratio',
	read: 'Read',
	reason: 'Reason',
	reject: 'Reject',
	rejected: 'Rejected',
	rejection: 'Rejection',
	received: 'Received',
	receivable: 'Receivable',
	receivables: 'Receivables',
	records: 'Records',
	refresh: 'Refresh',
	reference: 'Reference',
	region: 'Region',
	regional: 'Regional',
	relationship: 'Relationship',
	related: 'Related',
	released: 'Released',
	remark: 'Remark',
	remarks: 'Remarks',
	remove: 'Remove',
	rental: 'Rental',
	rented: 'Rented',
	repeat: 'Repeat',
	replace: 'Replace',
	report: 'Report',
	reporting: 'Reporting',
	reports: 'Reports',
	request: 'Request',
	requests: 'Requests',
	required: 'Required',
	reset: 'Reset',
	revenue: 'Revenue',
	rfq: 'RFQ',
	role: 'Role',
	roles: 'Roles',
	row: 'Row',
	sample: 'Sample',
	sale: 'Sale',
	sales: 'Sales',
	sanction: 'Sanction',
	sanction_date: 'Sanction Date',
	sanctioned: 'Sanctioned',
	save: 'Save',
	scheduled: 'Scheduled',
	score: 'Score',
	scrap: 'SCRAP',
	see: 'See',
	search: 'Search',
	select: 'Select',
	selected: 'Selected',
	sell: 'Sell',
	seller: 'Seller',
	services: 'Services',
	set: 'Set',
	setup: 'Setup',
	service: 'Service',
	serviced: 'Serviced',
	serviceable: 'Serviceable',
	share: 'Share',
	sheets: 'HR Sheets',
	similar: 'Similar',
	skip: 'Skip',
	sku: 'SKU',
	slug: 'Slug',
	so: 'SO',
	source: 'Source',
	source_link: 'Source Link',
	spo: 'SPO',
	spoc: 'SPOC',
	sub_role: 'Sub Role',
	summary: 'Summary',
	supply: 'Supply',
	start: 'Start',
	stage: 'Stage',
	state: 'State',
	statement: 'Statement',
	status: 'Status',
	stock: 'Stock',
	stop: 'Stop',
	sub: 'Sub',
	submit: 'Submit',
	submitted: 'Submitted',
	successfully: 'Successfully',
	supplier: 'Supplier',
	suppliers: 'Suppliers',
	supported: 'Supported',
	overdue: 'Overdue',
	partner: 'Partner',
	past: 'Past',
	path: 'Path',
	photo: 'Photo',
	photos: 'Photos',
	person: 'Person',
	personal: 'Personal',
	pieces: 'Pieces',
	pod: 'POD',
	previous: 'Previous',
	projects: 'Projects',
	project: 'Project',
	punchorder: 'Punch Order',
	tags: 'Tags',
	target: 'Target',
	task: 'Task',
	tasks: 'Tasks',
	tax: 'Tax',
	tc: 'TC',
	tcs: 'TCS',
	tds: 'TDS',
	team: 'Team',
	test: 'Test',
	tenure: 'Tenure',
	thickness: 'Thickness(mm)',
	thicknesss: 'Thickness',
	time: 'Time',
	title: 'Title',
	tnc: 'T&C',
	to: 'To',
	tools: 'Tools',
	ton: 'ton',
	trader: 'Supplier',
	transaction: 'Transaction',
	trip: 'Trip',
	total: 'Total',
	turnover: 'Turnover',
	type: 'Type',
	unaccept: 'Unaccept',
	unclaimed: 'Unclaimed',
	underwriting: 'Underwriting',
	units: 'Units',
	unregistered: 'Unregistered',
	upcoming: 'Upcoming',
	update: 'Update',
	upload: 'Upload',
	uploaded: 'Uploaded',
	url: 'Url',
	user: 'User',
	users: 'Users',
	utility: 'Utility',
	value: 'Value',
	validity: 'Validity',
	vehicle: 'Vehicle',
	verify: 'Verify',
	verified: 'Verified',
	vernacularize: 'Vernacularize',
	video: 'Video',
	view: 'View',
	visibility: 'Visibility',
	website: 'Website',
	weight: 'Weight',
	weightage: 'Weightage',
	winning: 'Winning',
	word: 'Word',
	x: 'EX',
	yes: 'Yes',
	your: 'Your',
	waste: 'Waste',
	width: 'Width',
	wise: 'Wise',
	zoho: 'Zoho',
	zone: 'Zone',

	aadhar_required: 'Aadhaar is required',
	active_auction: 'Active Auctions',
	accept_quote: 'Accept Quote',
	add_address: 'Add Address',
	add_item: 'Add item',
	add_new_item: 'Add New Item',
	add_remarks: 'Add Remarks',
	address_book: 'Address book',
	admins_message: 'Admins Message',
	auction_id: 'Auction Id',
	add_new_quote: 'Add New Quote',
	all_auction: 'All Auctions',
	all_requests: 'All Requests',
	allotted_weight: 'Alloted Weight',
	amount_lakh: 'Amount in Lakh',
	apply_credit: 'Apply For Credit',
	available_items: 'Available items',
	assign_enquiry: 'Assign enquiry',
	assign_auction: 'Assign Auctions',
	associated_suppliers: 'Associated Suppliers',
	average_costmt: 'Average cost / MT',
	back_orders: 'Back to Orders',
	back_factories: 'Back to Factories',
	back_machines: 'Back to Machines',
	beams_joist: 'MS Beams|Joist',
	best_quotes: 'Best Quotes',
	best_prices: 'Best Prices',
	bid_accepted: 'Bid accepted',
	bid_auction: 'Bid on Material',
	bid_details: 'Bid details',
	bid_successfully: 'Bid %s successfully.',
	billing_address: 'GST for Billing Address',
	bills_generated: 'Bills Generated',
	business_category: 'Business Category',
	business_sales: 'Business (Sales)',
	business_purch: 'Business (Purchase)',
	business_purchase: 'Business purchase',
	buyer_contact: 'Buyer Contact',
	buyer_quote: 'Buyer quote',
	cancel_enq: 'Cancel enquiry',
	category_name: 'Category Name',
	category_id: 'Category Id',
	change_address: 'Change address',
	company_name: 'Company Name',
	company_industry: 'Company Industry',
	concerned_manager: 'Concerned Manager',
	contribution_margin: 'Contribution Margin Per MT',
	converted_order: 'Converted to Order',
	cost_metrics: 'Cost Metrics',
	close_enq: 'Close Enq',
	closes_on: 'Closes on',
	cost_mt: 'COSTMT',
	connect_zoho: 'Connect to ZOHO',
	custom_fields: 'Custom fields',
	custom_details: 'Custom Details',
	create_auction: 'Create Auction',
	create_enquiry: 'Create Enquiry',
	create_category: 'Create category',
	create_product: 'Create product',
	credit_book: 'Credit Book',
	credit_for: 'Credit for',
	credit_discount: 'Credit(Discount)',
	daily_capacity: 'Daily capacity',
	delivery_location: 'Delivery Location',
	delivery_receipt: 'Delivery Receipt (POD)',
	digit_gstin: '15-digit GSTIN',
	digits_otp: '6-digits OTP',
	digital_experience: 'Digital Experience',
	dharamkata_slip: 'Weighment Slip',
	dispatch_location: 'Dispatch Location',
	district_court: 'District Court',
	drt_court: 'Drt Court',
	edit_quote: 'Edit quote',
	end_date: 'End date',
	ex_delhi: 'DELHI',
	ex_delhi_short: 'DL',
	ex_raipur: 'RAIPUR',
	ex_raipur_short: 'RCT',
	ex_mumbai: 'MUMBAI',
	ex_mumbai_short: 'MUM',
	ex_bangalore: 'BANGALORE',
	ex_bangalore_short: 'BLR',
	ex_hyderabad: 'HYDERABAD',
	ex_hyderabad_short: 'HYD',
	expected_delivery: 'Expected delivery',
	enter_name: 'Enter a name',
	my_profile: 'My Profile',
	enquiry_date: 'Enquiry date',
	enquiry_id: 'Enquiry Id',
	enquiry_items: 'Enquiry items',
	evaluation_criteria: 'Evaluation Criteria',
	expiry_date: 'Expiry Date',
	excess_ctc: 'Excess CTC@18% (Lacs)',
	factory: 'Factory',
	factory_name: 'Factory name',
	final_scores: 'Final Scores',
	final_report: 'Final Report',
	finalise_quote: 'Finalise quote',
	finalize_quote: 'Finalize Quote',
	forgot_pwd: 'Forgot password?',
	fullfill_enquiry: 'Fulfill Enquiry',
	full_name: 'Full Name',
	go_now: 'Go now',
	cr_coil: 'CR COIL',
	gp_coil: 'GP COIL',
	gps_link: 'GPS Link',
	gst_18: 'GST (18%)',
	gstin_required: 'GSTIN is required',
	hsn_code: 'HSN Code',
	has_report: '#Report',
	high_court: 'High Court',
	items_cost: 'Items Cost',
	image_able: 'image available',
	image_available: '%s image %s available',
	images_available: 'images available',
	in_review: 'In Review',
	invalid_pincode: 'Invalid pincode',
	inventory_details: 'Inventory details',
	invoice_amount: 'Invoice amount',
	invoice_no: 'Invoice No',
	invoice_number: 'Invoice number',
	invoice_numbers: 'Invoice Numbers',
	is_top: 'Is Top?',
	input_products: 'Input Products',
	order_id: 'Order Id',
	output_products: 'Output Products',
	list_inventory: 'List Inventory',
	listing_id: 'Listing Id',
	litigation_detail: 'Litigation Detail',
	logistics_charges: 'Logistics Charges',
	logistics_id: 'Logistics Id',
	logistics_items: 'Logistics items',
	logistics_requests: 'Logistics Requests',
	logistics_order: 'Logistics Order',
	logistics_partner: 'Logistics Partner',
	logistics_price: 'Logistics Price',
	logistics_support: 'Logistics Support',
	machine_name: 'Machine name',
	machine_description: 'Machine description',
	machines_count: 'Machines count',
	machine_make: 'Machine make',
	machine_type: 'Machine type',
	mb_documents: 'MB Documents',
	manage_auctions: 'Manage Auctions',
	manage_enquiries: 'Manage Enquiries',
	manage_factories: 'Manage Factories',
	manage_orders: 'Manage Orders',
	manage_enquiry_orders: 'Manage Enquiry Orders',
	manage_auction_orders: 'Manage Auction Orders',
	manage_purchase_orders: 'Manage Purchase Orders',
	manage_supply_orders: 'Manage Supply Orders',
	manage_logistics_orders: 'Manage Logistics Orders',
	marketing_expense: 'Marketing Expense',
	max_lth: 'Max Length',
	max_score: 'Max Score',
	merge_user_success: 'Merge user successfully',
	media_url: 'Media Url',
	meta_title: 'Meta Title',
	meta_description: 'Meta Description',
	moq_mt: 'MOQ (MT)',
	mob_num: 'Mobile number',
	monthly_target: 'Monthly Target (in Cr)',
	minimum_order_quantity_mt: 'Minimum Order Quantity (MT)',
	my_auctions: 'My Auctions',
	my_enquiries: 'My Enquiries',
	name_required: 'Name is required',
	nclt_court: 'Nclt Court',
	net_margin: 'Net Margin Per MT',
	num_machines: 'Number of machines',
	non_associated_suppliers: 'Non Associated Suppliers',
	not_available: 'Not available',
	not_data_found: 'not data found',
	no_auctions_found: 'No Auctions found',
	no_documents: 'No documents',
	no_machines_added_yet: 'No machines added yet',
	og_image: 'Open Graph Image',
	og_locale: 'Open Graph Locale',
	og_url: 'Open Graph URL',
	ordered: 'Ordered',
	order_ids: 'Order IDs',
	order_items: 'Order items',
	order_lost: 'Order Lost',
	order_summary: 'Order Summary',
	order_placed: 'Order placed',
	open_auction: 'Open Auctions',
	open_enquiries: 'Open Enquiries',
	opening_price: 'Opening Price',
	ordered_weight: 'Ordered Weight',
	owner_noc: 'Confirmation from landlord',
	payment_delivery: 'Payment on Delivery',
	payment_summary: 'Payment Summary',
	payment_terms: 'Payment Terms',
	payment_tenure: 'Credit Days',
	ppgi_sheets: 'PPGI SHEETS',
	preferred_brand: 'Preferred Brand',
	price_validity: 'Price Validity',
	promote_trader: 'Promote to SUPPLIER',
	product_name: 'Products',
	product_id: 'Product Id',
	project_name: 'Project Name',
	price_range: 'Price Range(MT)',
	preferred_category: 'Preferred Category',
	punch_order: 'Punch Order',
	purchase_invoices: 'Purchase Invoices',
	purchase_order: 'Purchase orders',
	order_date: 'Order date',
	quotation_amount: 'Quotation amount',
	quote_accepted: 'Quote accepted',
	quote_per_kg: 'Quote Per KG',
	quoted_amount_x: 'Quoted Amount (EX)',
	quote_received: 'Quotes Received',
	quotes_submitted: 'Quotes Submitted',
	raise_request: 'Raise request',
	rate_validity: 'Rate Validity',
	reactivate: 'Re-Activate',
	receiving: 'Receiving',
	request_logistics: 'Request Logistics',
	request_kyc: 'Request KYC',
	received_warehouse: 'Received at warehouse',
	ref_no: 'Ref No',
	rent_agreement: 'Rent Agreement',
	required_weight: 'Required Weight',
	rera_court: 'Rera Court',
	round_bars: 'MS Round Bars',
	running_order_book: 'Running Order Book',
	sale_invoices: 'Sale Invoices',
	sale_input: 'Sales Input',
	sale_team: 'Sales Team',
	sale_order: 'Sale order',
	sanction_required: 'Sanction is required',
	scoring_criteria: 'Scoring Criteria',
	see_quote: 'See quote',
	see_quotes: 'See Quotes',
	search_product: 'Search product',
	set_sales_primary: 'Set as Sales primary',
	set_supply_primary: 'Set as Supply primary',
	select_a_category: 'Select a category',
	select_a_partner: 'Select a Partner',
	select_a_product: 'Select a product',
	select_a_type: 'Select a type',
	select_address: 'Select address',
	select_brand: 'Select brand',
	select_brands: 'Select brands',
	select_buyer: 'Select buyer',
	select_category: 'Select category',
	select_date: 'Select Date',
	select_employee: 'Select employee',
	select_employee_s: 'Select employee(s)',
	select_status: 'Select status',
	select_partner: 'Select Partner',
	select_process: 'Select process',
	select_product: 'Select product',
	select_role: 'Select a role',
	select_type: 'Select type',
	select_user: 'Select user',
	selfattested: 'Self-attested',
	servicable_weight: 'Servicable Weight',
	serviced_weight: 'Serviced Weight',
	serviced_weight_mt: 'Serviced Weight (MT)',
	share_enquiry: 'Share Enquiry',
	share_enquiry_whapps: 'Share Enquiry (WhatsApp)',
	shareholding_pattern: 'Shareholding Pattern',
	sheets_plates: 'MS SHEETS/PLATES',
	shipping_address: 'Shipping address',
	shipping_details: 'Shipping details',
	shipping_location: 'Shipping Location',
	shipping_pincode: 'Shipping Pincode',
	sign_in: 'Sign In',
	sign_up: 'SignUp',
	start_date: 'Start date',
	supplier_by_inventory: 'Supplier Inventory',
	submit_quote: 'Submit quote',
	supplier_inventory: 'Supplier Inventory',
	supreme_court: 'Supreme Court',
	square_pipe: 'MS Square Pipe',
	other_details: 'Other details',
	privacy_policy: 'Privacy Policy',
	process_name: 'Process name',
	loading_employees: 'Loading employees...',
	target_achieved: 'Target Achieved (in Cr)',
	tc_reqd: 'TC Required?',
	tc_avl: 'TC Available?',
	tc_document: 'TC document',
	tenure_required: 'tenure is required',
	terms_service: 'Terms of Service',
	test_certificate: 'Test Certificate',
	thumbnail_url: 'Thumbnail Url',
	tmt_bars: 'MS TMT BARS',
	tribunal_court: 'Tribunal Court',
	total_amount: 'Total Amount',
	total_amount_for: 'Total Amount (FOR)',
	total_balance: 'Total balance',
	total_bills: 'Total bills',
	total_business: 'Total business',
	total_credit: 'Total credit',
	total_debit: 'Total debit',
	total_items: 'Total Items',
	total_gst: 'Total GST',
	total_quote: 'Total quote',
	total_requirement: 'Total Requirement (in MT)',
	total_sales: 'Total sales',
	total_weight: 'Total Weight',
	total_transactions: 'Total Transactions',
	track_order: 'Track Order',
	tracking_link: 'Tracking Link',
	trading_expense: 'Trading Expense',
	transaction_type: 'Transaction Type',
	pan_required: 'PAN is required',
	process_type: 'Process Type',
	selected_items: 'Selected items',
	ordered_weight_mt: 'Ordered Weight (MT)',
	upcoming_auction: 'Upcoming Auctions',
	update_exit: 'Update & Exit',
	update_quote: 'Update quote',
	upload_again: 'Upload again',
	upload_images: 'Upload images',
	used: 'Used',
	used_weight: 'Used Weight (MT)',
	user_name_required: 'User name is required',
	verify_auction: 'Verify Auctions',
	verified_suppliers: '1000+ Verified Suppliers',
	view_enquiry: 'View enquiry',
	view_quote: 'View quote',
	your_bids: 'Your Bids',
	warehouse_capacity: 'Warehouse Capacity(MT)',
	weight_m: 'Weight/mtr',
	weight_mt: 'Weight(MT)',
	weight_mt_units: 'Weight (MT) & Units',
	wlcm_to: 'Welcome to',
	zoho_contact: 'ZOHO Contact Id',
	zoho_customer: 'ZOHO Customer Id',
	add_new_address: 'Add a new address',
	add_buyer_items: 'Add buyer & items',
	add_commodity_success: 'Add Commodity Successfully',
	add_dispatch_address: 'Add dispatch address',
	add_item_details: 'Add item details',
	add_trip_success: 'Add trip Successfully',
	added_waste_item: 'Added waste item successfully',
	after_process_images: 'After process images',
	after_process_photos: 'After process photos',
	alloted_weight_units: 'Alloted Weight (MT) & Units',
	ask_principal_details: 'Ask for project details & principal',
	attribute_is_mandatory: 'attribute is mandatory',
	average_past_delays: 'Average Past Delays',
	back_cloud_orders: 'Back to Cloud Orders',
	back_manage_orders: 'Back to Manage Orders',
	before_process_img: 'Before process images',
	before_process_photos: 'Before process photos',
	bid_valid_till: 'Bid valid till',
	brand_of_machine: 'Brand of the machine',
	bspl_req_sent: 'BSPL credit request has been sent',
	capacity_per_day_mt: 'Capacity per day in MT',
	create_factory_successfully: 'Factory created successfully',
	create_underwriting_successfully: 'New report submitted successfully',
	commodity_prices_successfully: 'Commodity Prices added successfully',
	contact_num_required: 'contact number is required',
	click_manage_enq: 'Click to manage enquiry',
	confirm_invoice_deleted: 'Confirm if you want to delete invoice ?',
	confirm_upgrade_user: 'Confirm if you want to upgrade user as a %s ?',
	convert_to_order: 'Convert to Order',
	converted_to_order: 'Converted to Order',
	cost_per_mt: 'COST/MT',
	custom_machine_details: 'Custom Machine Details',
	description_for_machine: 'Description for the machine',
	delete_admin_info_text: 'All the information and data of selected Admin will be transferred to the new Admin.',
	delete_blog_successfully: 'Delete blog successfully',
	delete_news_successfully: 'Delete news successfully',
	delete_bill_successfully: 'Delete Bill successfully',
	delete_commodity_successfully: 'Delete commodity successfully',
	delete_ledger_successfully: 'Delete Ledger successfully',
	dispatch_weighment_slip: 'Dispatch Weighment Slip',
	distance_calculator_success: 'Distance calculator is successfully Uploaded',
	drop_weighment_slip: 'Drop Weighment Slip',
	during_process_images: 'During process images',
	during_process_photos: 'During process photos',
	edit_item_details: 'Edit item details',
	enquiry_open_till: 'Enquiry Open Till',
	enquiry_cannot_edited: 'Enquiry cannot be edited.',
	enquiry_updated_success: 'Enquiry updated successfully.',
	enter_drop_date: 'Enter drop date',
	enter_destination_pincode: 'Enter Destination pincode',
	enter_digit_gstin: 'Please enter a valid 15 digit GSTIN',
	enter_digit_otp: 'Please enter 6 digit OTP',
	enter_digit_aadhaar: 'Please enter a valid 12 digit Aadhaar',
	enter_location_pincode: 'Enter location pincode',
	enquiry_shared_success: 'Enquiry shared successfully',
	enter_num_days: 'Enter number of days',
	enter_pan_gst: 'Please enter a valid PAN or GST',
	enter_payment_tenure: 'Enter a valid payment tenure',
	enter_request_amount: 'Enter request amount',
	enter_request_tenure: 'Enter request tenure (day`s)',
	enter_serviced_weight: 'Enter serviced weight',
	enter_ordered_weight: 'Enter ordered weight',
	enter_serviceable_weight: 'Enter serviceable weight',
	enter_val_meters: 'Enter value in meters',
	enter_val_millimeters: 'Enter value in millimeters',
	enter_origin_pincode: 'Enter Origin pincode',
	enter_organisation_gst: 'Please enter the Organisation GST number',
	enter_max_price: 'Enter maximum price',
	enter_min_price: 'Enter minimum price',
	enter_logistics_order: 'Enter Logistics Order',
	enter_valid_email: 'Please enter a valid email',
	enter_valid_pan: 'Please enter a valid 10 digit PAN',
	enter_valid_num: 'Please enter a valid number',
	enter_valid_url_link: 'Please enter a valid url link',
	enter_valid_slug: 'Please enter a valid slug',
	enter_your_mobile_num: 'Enter your mobile number',
	enter_your_organisation: 'Enter your organisation details',
	everyday_utilisation_machine: 'Everyday utilisation of machine',
	expected_transit_time: 'Expected Transit Time (in Days)',
	general_machine_details: 'General Machine Details',
	fabrication_invoice_added: 'Fabrication invoice added successfully',
	factory_created_successfully: 'Factory created successfully',
	factory_order_created: 'Factory order created successfully',
	factory_order_updated: 'Factory order updated successfully',
	facility_flexible_payments: 'Credit Facility/ Flexible Payments',
	failed_created_enquiry: 'Failed to created enquiry.',
	failed_updated_enquiry: 'Failed to updated enquiry',
	fastest_response_time: 'Fastest Response Time',
	fetch_past_records: 'Fetch Past Records',
	fetch_zoho_contid: 'Fetch Zoho Contact ID',
	finance_feedback_update: 'Finance Feedback successfully Update',
	fsa_file_upload: 'FSA file has been upload',
	have_tc: 'Do you have Test Certificate for this product?',
	input_output_products: 'Input & Output Products',
	input_coil_thickness: 'Input Coil Thickness',
	input_coil_width: 'Input Coil Width',
	input_product_ctgy: 'Input Product Categories',
	input_order_items: 'Input Order Items',
	inventory_batch_no: 'Inventory batch number',
	inventory_from_factory: 'Inventory from Factory',
	inventory_updated_successfully: 'Inventory updated successfully',
	invoice_added_successfully: 'Invoice added successfully',
	invoice_deleted: 'Invoice deleted successfully',
	invoice_successfully_endorsed: 'Invoice is successfully endorsed',
	invoice_uploaded_successfully: 'Invoice uploaded successfully',
	is_trader_account: 'Trader',
	journey_not_found: 'Journey not found',
	preferred_payment_terms: 'Preferred Payment Terms',
	payment_terms_100: 'Payment Terms should be 100%.',
	msg_enter_payment_tenure_error: 'Enter Payment tenure when choosing credit!',
	msg_enter_lc_tenure_error: 'Enter LC Tenure when choosing LC',
	organisation_merged_success: 'Organisations merged successfully',
	organisation_merged_failure: 'Organisations merge failed',
	output_product_ctgy: 'Ouput Product Categories',
	output_order_items: 'Output Order Items',
	update_account_details: 'Update account details',
	update_item_details: 'Update item details',
	update_organisation_details: 'Update organisation details',
	is_final_quote: 'Is this the final quote?',
	is_tc_reqd: 'Is Test certificate required?',
	is_tc_available: 'Is TC available?',
	items_enquiry_order: 'Items from Enquiry / Order',
	login_other_num: 'Login with other number',
	login_with_email: 'Login with email',
	login_with_mobile: 'Login with mobile',
	logistics_handler: 'Logistics Handler',
	logistics_quotation: 'Logistics Quotation',
	logistics_quotation_mt: 'Logistics Quotation /MT',
	make_to_order: 'Make To Order',
	mb_ref_no: 'MB Ref No',
	mb_ref_number: 'MB Reference Number',
	mode_of_payment: 'Mode Of Payment',
	max_coil_weight_mt: 'Max Coil Weight (MT)',
	machine_capacity_day_mt: 'Machine capacity per day (MT)',
	management_approval_form: 'Management Approval Form',
	monthly_capacity_mt: 'Monthly Capacity (MT)',
	monthly_conversion_chart: 'Monthly Enquiry → Order Conversion',
	monthly_quotes_conversion_chart: 'Monthly Quotes → Order Conversion',
	monthly_supply_chart: 'Monthly Supply Quote → Order Conversion',
	monthly_sale_purchase_chart: 'Monthly Sale/Purchase Comparision',
	max_input_length_m: 'Max Input Length (m)',
	max_input_width_mm: 'Max Input Width (mm)',
	max_diagonal_tolerance_mm: 'Max Diagonal Tolerance (mm)',
	max_input_thinkness_mm: 'Max Input Thickness (mm)',
	max_lth_tolerance_mm: 'Max Length Tolerance (mm)',
	max_available: 'Maximum available %s',
	max_available_mt: 'Maximum available %s MT',
	max_weight_is_mt: 'Maximum weight is %s MT',
	max_canbe: 'Maximum can be %s',
	max_canbe_mt: 'Maximum can be %s MT',
	max_lth_mtr: 'Max length in meters',
	min_diagonal_tolerance_mm: 'Min Diagonal Tolerance (mm)',
	min_input_thinkness_mm: 'Min Input Thickness (mm)',
	min_input_width_mm: 'Min Input Width (mm)',
	min_weight_is_mt: 'Minimum weight is %s MT',
	min_lth_tolerance_mm: 'Min Length Tolerance (mm)',
	mob_num_required: 'Mobile number is required',
	no_addresses_found: 'No addresses found',
	min_bid_amount_is_mt: 'Mininum bid amount is %s MT',
	moq_is_mt: 'MOQ is %s MT',
	new_access_requested: 'New Access Requested',
	no_factory_found_id: 'No factory found with id %s',
	no_machine_found_id: 'No machine found with id %s',
	no_invtry_add_yet: 'No inventory added yet',
	no_img_add_yet: 'No images added yet',
	no_inventory_available: 'No inventory available. Please select a different factory.',
	no_items_add_yet: 'No items added yet',
	no_items_order_enquiry: 'No items found in this order/enquiry',
	no_order_found_id: 'No order found with id %s',
	no_of_pages: 'No of Pages',
	no_temp_found: 'no templates found',
	no_test_certificate: 'No Test Certificate added',
	num_of_days: 'Number of days',
	order_amount: 'Order Amount',
	order_book_cr: 'Order Book (in Cr)',
	order_weight: 'Order Weight',
	one_stop_solution: 'One-stop Solution',
	order_journey_updated: 'Order journey updated successfully',
	original_invoice_number: 'Original Invoice Number',
	only_aplha_special_char: 'Only aplhanumeric and special characters like _ $ # @ are allowed',
	reason_for_delay: 'Reason for Delay',
	reason_for_delay_placeholder: 'Reason for delay from principal and estimated date of payment',
	req_credit_sent: 'Request credit has been sent',
	set_as_primary: 'Set as primary',
	start_add_items: 'Start adding Items',
	select_a_brand: 'Select a brand',
	select_ability_issue: 'Select ability issue',
	select_closing_reason: 'Select closing reason',
	select_machine_process: 'Select machine process',
	select_inventroy_factory: 'Select Inventory from Factory',
	select_order_date: 'Select order date',
	select_quote_type: 'Select Quote Type',
	select_rejection_reason: 'Select rejection reason 1',
	select_items_enquiry_order: 'Select Items from Enquiry / Order',
	serviced_weight_kg: 'Serviced weight (kg)',
	serviced_weight_sku: 'Serviced weight per SKU',
	stock_img_uploaded: 'Stock images uploaded successfully',
	stock_photo_unavailable: 'Stock photo unavailable',
	pancard_upload_success: 'PAN card details saved successfully',
	upload_success: 'File uploaded successfully',
	payment_terms_in_days: 'Payment Terms (in Days)',
	price_per_kg: 'Price per kg',
	price_per_ton: 'Price/Ton',
	price_validity_moq: 'Price Validity & MOQ',
	price_too_low: 'Price is too low',
	point_contact_name: 'Point of Contact Name',
	point_contact_required: 'Point of contact name is required',
	point_contact_mobile_num: 'Point of Contact Mobile Number',
	ponit_contact_num: 'Point of contact number',
	profile_updated_sucess: 'Profile updated successfully',
	request_submit_sucess: 'Request submitted successfully',
	quote_acpt_success: 'Quote accepted successfully',
	request_trader_access: 'Request to become SUPPLIER',
	requested_supplier_rights: 'Requested Supplier Access?',
	received_warehouse_photos: 'Received at warehouse photos',
	redirecting_in_seconds: 'Redirecting in %s seconds, or',
	remove_all_supplier_invoice: 'The supplier invoices must be deleted before removing the supplier',
	set_primary_manager: 'Set as primary',
	success_created_machine: 'Successfully created machine',
	success_created_news: 'Successfully created news',
	success_updated_machine: 'Successfully updated machine',
	success_updated_news: 'Successfully updated news',
	tc_not_available: 'TC not available',
	tc_success_endorsed: 'TC is Successfully endorsed',
	test_certificate_tc: 'Test Certificate (TC)',
	total_amount_breakup: 'Total amount breakup',
	total_alloted_weight_mt: 'Total Alloted Weight (MT)',
	total_items_amount: 'Total items amount',
	total_logistics_amount: 'Total logistics amount',
	total_order_amount: 'Total Order Amount',
	total_order_weight: 'Total Order Weight',
	total_order_weight_mt: 'Total Order Weight(MT)',
	total_other_charge: 'Total other charges amount',
	total_serviced_weight: 'Total Serviced Weight',
	total_serviced_weight_mt: 'Total Serviced Weight(MT)',
	total_rejected_weight_mt: 'Total Rejected Weight(MT)',
	total_serviced_amount: 'Total Serviced Amount',
	total_quotation_amount: 'Total Quotation Amount',
	order_marked_completed: 'Order marked completed successfully',
	org_name_required: 'Organisation name is required',
	updated_factory_succss: 'Updated factory successfully',
	upload_more_images: 'Upload more images',
	user_already_exist: 'User Already Exist with given mobile number',
	weight_per_meter: 'Weight per meter',
	utilisation_per_day_mt: 'Utilisation per day (MT)',
	item_batch_num_exists: 'Item with batch number %s already exists. Please edit the details for the same item below.',
	related_ledgers_deleted: 'Related Ledgers will also be deleted!',
	related_order_ledgers_deleted: 'Related Order and Ledgers will also be deleted!',
	update_trip_successfully: 'Update trip Successfully',
	unable_assign_enquiry: 'Unable to assign enquiry. Try again',
	select_from_existing_addresses: 'Or, select from existing addresses',
	select_input_product_ctgy: 'Select input product categories',
	select_mb_ref_num: 'Select MB Reference Number',
	select_output_product_ctgy: 'Select output product categories',
	sign_you_accept_our: 'By signing in, you accept our',
	submit_supplier_quote_reason_befor_close: 'Please submit supplier quote reason before closing the enquiry.',
	submit_the_price_ton: 'Submit the prices in per ton.',
	stop_trip_successfully: 'Trip completed successfully',
	want_to_delete_record: 'Do you want to delete record?',
	partial_payment_agreement: 'Can customer be pushed & will agree for partial payment? include schedule of partial payment',
	partner_doesnt_any_factory_yet: 'Partner doesn`t have any factory yet.',
	provide_balance_sheet_pl: 'Please provide your Balance Sheet, P&L statement to improve your chances of Credit Approval.',
	provide_consent: 'Please provide consent to verify your aadhaar details',
	provide_consent_pan: 'Please provide consent to verify and fetch your PAN details',
	consent_aadhaar: 'Please provide consent to verify and fetch your aadhaar details from UIDAI',
	provide_details_director: 'Please enter company director Aadhaar details',
	pls_enter_admin_msg: 'Please Enter Admin Message',
	pls_enter_valid_mobile_num: 'Please enter a valid mobile number',
	pls_enter_vaild_enquiry_order: 'Please enter a valid enquiry / order id in basic details',
	pls_select_associate_supplier: 'Please Select Associated Supplier',
	pls_select_non_associate_supplier: 'Please Select Non Associated Supplier',
	pls_enter_associate_supplier_msg: 'Please Enter Associated Supplier Message',
	pls_enter_non_associate_supplier_msg: 'Please Enter Non Associated Supplier Message',
	pls_select_factory_start_adding_items: 'Please select a factoy to start adding items',
	pls_connact_ur_manager_reopen_enquiry: 'Please contact your Concerned Manager to Re-open the enquiry and get quotation from Metalbook.',
	pls_select_partner_first: 'Please select a partner first.',
	press_button_approximate_prices: 'Press Button to fetch approximate prices',
	quotation_has_success_submitted: 'Quotation has been successfully submitted',
	quotation_has_success_updated: 'Quotation has been successfully updated',
	updated_waste_item: 'Updated waste item successfully',
	upload_dispatch_weighment: 'Upload Dispatch Weighment',
	upload_drop_weighment: 'Upload Drop Weighment',
	upload_stamp_want_to_use_stamp: 'Upload Stamp if dont want to use the default MB stamp',
	view_basic_order_details: 'View basic order details',
	view_order_summary: 'View order summary',
	view_purchase_order: 'View purchase order',
	your_email_address: 'Your email address',
	your_full_name: 'Your full name',
	your_org_pan: 'Your organisation PAN',
	view_stock_photo: 'View stock photo',
	want_to_delete: 'Are you sure want to delete this item?',
	want_to_role: 'Are you sure want to change user role?',
	zoho_contact_ids: 'ZOHO Contact Id(s)',
	banner_ratio: 'Banner Image ratio : 100:43',

	click_icon_to_download: 'Click icon to download',
	all_utility: 'Electricity/ Water/ Internet/ Phone',

	add_atleast_item_create_enquiry: 'Add atleast one item to create an enquiry',
	add_atleast_item_create_order: 'Add atleast one item to create an order',
	add_no_pages_in_file: 'Add no of pages in the file',
	add_one_item_create_logistics: 'Add atleast one item to create a Logistics',
	admin_request_user_kyc: 'Are you sure you want to request the user for KYC?',
	admin_request_user_supplier: 'Are you sure you want to request the user as a Supplier?',
	average_cost_metric_tone: 'Average cost per metric tonne',
	all_progress_lost_r_you_sure: 'All the progress will be lost. Are you sure?',

	click_view_factory_inventory: 'Click to view your factory inventory',
	enter_basic_order_details: 'Enter basic order details',
	enter_comma_separated_filed: 'Enter comma separated values if multiple for each field',
	enter_9_digit_enquiry: 'Enter 9 digit Enquiry / Order ID',
	enter_11_digit_enquiry: 'Enter 11 digit Enquiry / Order ID',
	enter_valid_contact_num: 'Please enter a valid contact number',
	enter_serviced_weight_sku: 'Enter serviced weight per SKU',
	enquiry_has_created_success: 'Enquiry has been created successfully',
	enquiry_final_form_submit: 'Form Submitted Successfully',
	fill_req_org_details: 'Fill required organisation details',
	freight_calculator_not_found: 'We don’t have the latest prices for these pincodes, please contact your concerned manager regarding this issue.',
	invoices_bills_added_successfully: 'Invoices/Bills added successfully',
	invoice_same_doc_num: 'Invoice with same document number already exists.',
	max_60_days_allowed: 'Maximum 60 days allowed',
	mbook_will_back_you: 'Metalbook will get back to you',
	mbook_back_with_best_quotes: 'Metalbook will get back to you with best quotes',
	new_buy_sell_steel: 'New way to Buy & Sell Steel',
	upload_distance_xl_sheet: 'Upload Distance Excel Sheet (CSV not allowed)',

	err_failed_create_request: 'Failed to create a request',
	err_failed_update_profile: 'Failed to update profile',
	err_failed_destination_pincode: 'Failed to destination pincode details',
	err_failed_origin_pincode: 'Failed to origin pincode details',
	err_while_creating_factory_try_again_ltr: 'Error while creating factory. Try again later.',
	err_while_updating_factory_try_again_ltr: 'Error while updating factory. Try again later.',
	err_generate_aadhaar_otp_failed: 'We are sorry, the UIDAI Server is down. Please try back in some time',

	add_final_supplier_quotes_berfor_place_order: 'Please add final supplier quotes before placing the order',
	add_relationship_manager_befor_enquiry_active: 'Please add relationship manager(s) before making enquiry active',
	all_changes_lost_sure: 'All the changes will be lost. Are you sure?',
	all_progress_lost_sure: 'All the progress will be lost. Are you sure?',
	click_here_add_delete_modify_factory_inventory: 'Click here to add, delete or modify this factory`s inventory',

	msg_already_existing_temp:
		'Already existing templates are customisable and can be used directly as well. Please click on Create New to create a machine from scratch.',
	msg_atleast_one_ctgy_attri_must_be_selected: 'Atleast one category attribute must be selected',
	msg_are_you_sure_want_to_delete_doc: 'Are you sure want to delete this document?',
	msg_are_you_sure_want_to_delete_this_invoice: 'Are you sure want to delete this invoice?',
	msg_are_you_sure_want_to_delete_this_user: 'Are you sure want to delete this user?',
	msg_are_you_sure_want_to_delete_this_organisation: 'Are you sure want to delete this Organisation?',
	msg_are_you_sure_want_to_delete_admin: 'Are you sure you want to delete this admin?',
	msg_are_you_sure_want_to_delete_org: 'Are you sure you want to delete this organisation?',
	msg_are_you_sure_want_to_delete_faq: 'Are you sure you want to delete the FAQ?',
	msg_auction_assigned: 'Auction successfully assigned to employee(s)',
	msg_auction_unable_assign_auction: 'Unable to assign auction. Try again',
	msg_auction_created_successfully: 'Auction created successfully.',
	msg_auction_failed_to_create: 'Failed to create auction. Try again.',
	msg_failed_add_waste_item: 'Failed to add waste item. Try again',
	msg_failed_create_factory_order: 'Failed to create factory order. Try again',
	msg_failed_to_create_machine: 'Failed to create machine. Try again',
	msg_failed_enquiry_order: 'Failed to fetch enquiry/order details',
	msg_failed_partner_factories: 'Failed to fetch partner factories',
	msg_failed_pincode_details: 'Failed to fetch pincode details',
	msg_failed_mark_order_complete: 'Failed to mark order complete. Try again',
	msg_failed_update_factory_order: 'Failed to update factory order. Try again',
	msg_failed_update_inventory: 'Failed to update inventory',
	msg_failed_to_update_machine: 'Failed to update machine. Try again',
	msg_failed_to_update_news: 'Failed to update news. Try again',
	msg_failed_update_order_journey: 'Failed to update order journey. Try again',
	msg_auction_failed_to_update: 'Failed to update auction. Try again.',
	msg_auction_updated_successfully: 'Auction updated successfully',
	msg_failed_update_waste_item: 'Failed to update waste item. Try again',
	msg_auction_verified: 'Auction verified successfully',
	msg_banner_created: 'Banner created successfully',
	msg_banner_editted: 'Banner updated successfully',
	msg_blog_created: 'Blog created successfully',
	msg_blog_create_fail: 'Blog not created!',
	msg_blog_editted: 'Blog updated successfully',
	msg_blog_edit_fail: 'Blog not updated!',
	msg_are_you_sure_to_delete_lead: 'Are you sure you want to delete the lead?',
	msg_batch_no_cannot_updated:
		'Batch number cannot be updated. If you want to change batch number, please delete this item, and create a new one with the updated batch number.',
	msg_bid_submitted_successfully: 'Bid submitted successfully.',
	msg_bid_updated_successfully: 'Bid updated successfully.',
	msg_buyer_invoice_added_successfully: 'Buyer invoice added successfully',
	msg_buyer_po_uploladed_successfully: 'Buyer PO uploaded successfully',
	msg_convert_to_order: 'Convert to order successfully',
	msg_click_manage_auction: 'Click to manage auction',
	msg_document_uploaded: 'Document uploaded successfully',
	msg_document_deleted_success: 'Document deleted successfully',
	msg_document_delete_failed: 'Document deletion failed',
	msg_enquiry_success: 'Enquiry submitted successfully',
	msg_wrong_value: 'Value cannot be less than or equal to zero',
	msg_enquiry_assigned: 'Enquiry successfully assigned to employee(s)',
	msg_enquiry_verified: 'Enquiry Verified successfully',
	msg_enquiry_cancelled: 'Enquiry has been cancelled',
	msg_cost_metrics_submitted: 'Cost metrics saved successfully',
	msg_cost_metrics_submission_failed: 'Cost metrics not saved!',
	msg_failed_to_update_invoice_details: 'Failed to update invoice details',
	msg_img_upload_failed: 'Image upload failed.',
	msg_img_upload_failed_try_again: 'Image upload failed. Try again',
	msg_invty_photo_upload_err: 'Inventory photo upload error:',
	msg_invoice_details_updated: 'Invoice details updated',
	msg_item_already_added: 'Enquiry Item is already added',
	msg_logistics_success: 'Logistics submitted successfully',
	msg_logistics_update: 'Logistics updated successfully',
	msg_logistics_price_add: 'Logistics Price add successfully',
	msg_logistics_price_update: 'Logistics Price updated successfully',
	msg_quotes_submitted: 'Quotes submitted successfully',
	msg_quotes_editted: 'Quotes updated successfully',
	msg_order_created: 'Congratulations! New Order Created.',
	msg_order_created_error: 'Error occured while creating order',
	msg_order_editted: 'Order updated successfully',
	msg_order_update_fail: 'Some error occurred while updating order!',
	msg_payment_update: 'Payment mode update successfully',
	msg_pls_select_atleast_one_brand: 'Please select atleast one brand',
	msg_product_created: 'Product created successfully',
	msg_product_editted: 'Product edit successfully',
	msg_brand_created: 'Brand created successfully',
	msg_category_created: 'Category created successfully',
	msg_category_editted: 'Category edit successfully',
	msg_commodity_created: 'Commodity created successfully',
	msg_commodity_editted: 'Commodity edit successfully',
	msg_select_from_exist_temp_create_new: 'Select from existing templates or create new',
	msg_select_user: 'Please select a user',
	msg_select_supplier: 'Please select a Supplier',
	msg_payment_update: 'Payment mode update successfully',
	msg_success_upload_img: 'Successfully uploaded the image(s)',
	msg_success_upload_tc: 'Successfully uploaded TC',
	msg_supplier_invoice_added_successfully: 'Supplier invoice added successfully',
	msg_supplier_po_uploaded_successfully: 'Supplier PO uploaded successfully',
	msg_organisation_deleted: 'Organisation deleted successfully',
	msg_organisation_not_deleted: 'Organisation not deleted!',
	msg_user_created: 'User created successfully',
	msg_user_deleted: 'User deleted successfully',
	msg_user_editted: 'User updated successfully',
	msg_transaction_added: 'Transaction addded successfully',
	msg_transaction_amount: 'Please enter transaction amount',
	msg_to_buyer: 'Message to Buyer',
	msg_to_associated_suppliers: 'Message to Associated Suppliers',
	msg_to_non_associated_suppliers: 'Message to Non Associated Suppliers',
	msg_user_promoted_trader: 'User promoted to SUPPLIER successfully',
	msg_enter_supplier_quotes: 'Please enter supplier quotes.',
	msg_enter_quotes: 'Please finalize supplier and buyer quotes before converting the enquiry to order',
	msg_enter_quotes_trader: 'We are still getting best quotes for you. Contact Concerned Manager',
	msg_quote: [
		'GST excluded in prices',
		'Fill Logistics Quotation for FOR',
		'You can also bid on partial order',
		'In case of no logistic support, submit only X quote, quote rank will not be shown',
		'Manager will reach out to suppliers with top 5 quotes for final negotiations',
	],
	msg_quote_buyer: [
		'GST excluded in prices.',
		'Please contact Metalbook concerned manager for any queries.',
		'Please check the brand and weight offered before confirming the order.',
	],
	msg_quote_trader: [
		'GST excluded in prices.',
		`Choose EX for Ex- Godown price ; in case of logistics support - fill your logistics price, else keep it 'Zero' in case you need logistics support from Metalbook.`,
		'Same way, please choose FOR, for Freight on Road price.',
		'You can also submit quote for partial order quantity',
	],
	msg_collection_created_success: 'Collection created successfully',
	msg_collection_created_failure: 'Collection not created!',
	msg_receivable_edited_success: 'Collection remark edited successfully',
	msg_receivable_edited_failure: 'Collection remark not edited',
	msg_financials_remark_added_success: 'Remark added to the financials successfully',
	msg_financials_fetched_success: 'Financials fetched successfully',
	msg_financials_fetched_failed: 'Financials not fetched!',
	msg_financial_data_not_found: '*Not found',
	msg_collection_remark_added_success: 'Remark added to the collection successfully',
	msg_collection_remark_added_failure: 'Remark not added to the collection!',
	msg_service_weight: 'Serviced weight will be updated in some time after uploading the bill',
	msg_available_phase_2: 'Feature will be available soon',
	msg_user_fill_details: 'Please fill your organisation details to become SUPPLIER',
	msg_request_submitted: "Your request to become 'SUPPLIER' has been recorded",
	msg_invalid_phone_number: 'Invalid Phone number, please check and try again.',
	msg_too_many_requests: 'Too many login requests done, please try again after some time.',
	msg_maximum_user_count_exceeded: 'Maximum user count reached. Please try again after sometime.',
	msg_otp_sent_mobile: 'Message has been sent to registered mobile',
	msg_invalid_verification_code: 'Invalid OTP. Please check and try again.',
	msg_otp_verified: 'OTP is confirmed.',
	msg_remark_added: 'User remark submitted successfully',
	msg_share_any_more_details: 'Share any more details..',
	msg_maximum_file_upload: 'Maximum file upload',
	msg_is_in_top_three_quote: 'Is in top three Quote.',
	msg_not_in_top_three_quote: 'Not in top three Quote.',
	msg_in_top_3_quote: 'In Top 3 Quote?',
	msg_verify_email:
		'Your email is not verfied. We have sent a mail to your email address. Please check your spam folder if you couldnt locate the mail.',
	msg_password_reset_mail: 'Please follow the steps sent to the email to reset the password',
	msg_email_added_successfully: 'Email and password successfully updated',
	msg_enter_email_password: 'Please enter email address and password',
	msg_download_started: 'Download Started',
	msg_no_special_char: 'Do not add special characters like "/ - _ @" etc.',
	msg_supplier_quote_successfully_added: 'Supplier quote successfully added',
	msg_action_failed_try_again: 'Action failed. Try again.',
	msg_failed_to_fetch: 'Failed to fetch details. Try again.',
	msg_failed_create_order_try_again: 'Failed to create order. Try again',
	msg_quote_accepted: 'Quote Accepted',
	msg_quote_unaccepted: 'Quote Unaccepted',
	msg_failed_accept_quote_try_again: 'Failed to accept quote. Try again',
	msg_failed_unaccept_quote_try_again: 'Failed to unaccept quote. Try again',
	msg_no_enquiry_items_available_try_diff_enquiry: 'No enquiry items available, please try a different enquiry / order id.',
	msg_update_colse_reason_suppliers_rejection_reson:
		'On updating the closing reason, the suppliers will be notified with the rejection reason selected. Please be careful while submitting the closing reason.',
	msg_this_site_protected_recaptcha: 'This site is protected by reCAPTCHA and the Google',
	msg_upload_document: 'Please upload the following documents of the',
	msg_video_kyc_completed: 'Video KYC already completed. Please upload rest of the documents.',
	msg_video_kyc_text:
		'I do hereby declare that the information submitted by me is true to the best of my knowledge. \
	\n I currently reside at[residential address], and I am running a business that deals in sale and purchase of MS Scrap. \
	\n My company address is[GST registered address], it is a self owned / rental premises.I also manage supply of goods from my self owned / rental warehouse, which is located at[Warehouse address - should be registered as additional place of business in GST certificate]. \
	\n I hereby, solemnly declare, that I am running a business which is compliant as per all rules and regulation, including, but not limited to, taxation rules, of the land of India.',
	msg_ifc_calculator: 'IFC prices are calculated at 18% per annum',
	msg_faq_added_success: 'Faq Added successfully',
	msg_faq_added_failure: 'Faq not added!',
	msg_credit_limit_added_success: 'Credit Limit added successfulyy',
	msg_credit_limit_added_fail: 'Credit limit not added!',
	msg_credit_limit_deleted_success: 'Credit limit deleted successfully',
	msg_credit_limit_deleted_fail: 'Credit limit not deleted!',
	msg_cheque_image_uploaded_success: 'Cheque Image uploaded successfully',
	msg_cheque_image_uploaded_failed: 'Cheque Image not uploaded!',

	msg_services_updated_success: 'Services updated successfully',
	msg_service_update_fail: 'Services not updates',

	msg_fill_cost_metrics_form: 'Order has been created.',
	msg_fill_punchorder_form: 'Fill the details if applicable.',

	msg_refresh_zoho_contact_success: 'Zoho contacts refreshed successfully',
	msg_refresh_zoho_contact_failed: 'Zoho contacts refresh failed!',

	msg_address_deleted_success: 'Address Deleted Successfully',
	msg_address_deleted_failed: 'Address Deletion Failed',
	want_to_delete_address: 'Click confirm to delete address.',

	msg_image_not_uploaded: 'Image not uploaded,try again',
	msg_file_exceeding_2_mb_content: 'Image size should be less than 2 MB!',
	placeholder_bid_per_mt: 'Bid per mt',
	placeholder_cost_per_mt: 'Cost per MT',
	placeholder_enter_batch_no: 'Enter batch number',
	placeholder_enter_cast_no: 'Enter cast number',
	placeholder_enter_comma_separated_refer_no: 'Enter Comma separated MB Reference Number',
	placeholder_enter_comma_separated_order_id: 'Enter Comma separated Order Id',
	placeholder_enter_factory_name: 'Enter factory name',
	placeholder_enter_invoice_amount: 'Enter invoice amount',
	placeholder_enter_invoice_date: 'Enter invoice date',
	placeholder_enter_invoice_number: 'Enter invoice number',
	placeholder_enter_logistics_quotation_per_mt: 'Enter Logistics Quotation per MT',
	placeholder_enter_lumpsum_logistics_quotation: 'Enter Lumpsum Logistics Quotation',
	placeholder_enter_minimum_order_quantity: 'Enter minimum order quantity',
	placeholder_enter_quantity: 'Enter quantity',
	placeholder_enter_quote_per_kg: 'Enter quote/kg',
	placeholder_enter_serviced_weight_kg: 'Enter serviced weight in kg',
	placeholder_enter_rejected_weight_kg: 'Enter rejected weight in kg',
	plceholder_enter_warehouse_capacity_metric_tonne: 'Enter warehouse capacity in metric tonne',
	placeholder_enter_weight_in_mt: 'Enter weight in MT',
	placeholder_enter_weight_to_be_alloted: 'Enter Weight to be Alloted',
	placeholder_enter_value_metric_tonne: 'Enter value in metric tonne',
	placeholder_enter_value_in_kg: 'Enter value in KG',
	placeholder_enter_your_bid_per_mt: 'Enter your bid per MT',
	placeholder_point_contact_name: 'Point of contact name',
	placeholder_quote_per_mt: 'Quote per MT',
	placeholder_select_brand: 'Enter your Brand',
	placeholder_select_category: 'Select a Category',
	placeholder_select_supplier: 'Select supplier',
	placeholder_select_product: 'Select a Product',
	placeholder_weight_metric_tonne: 'Weight in metric tonne',
	placeholder_weight_in_mt: 'Weight in MT',
	provide_one_below_details: 'Provide atleast one of the below details',
	provides_estimated_value_get_actual_prices:
		'This just provides an estimated value. Please get a quote from logistics partner to get the actual prices.',
	related_invoices_required: 'Related invoices is required',
	sanctioned_credit_facility_charged:
		'Sanctioned Credit Facility will be charged @18% annually. The credit is only available for the current order.',
	label_allot_weight: 'Allot Weight (MT)',
	label_closing_date_minimum_3_days: 'Closing Date (Minimum 3 Days)',
	label_metalbook_opening_bid: 'Metalbook opening bid / MT',
	label_minimum_order_quantity: 'Minimim Order Quantity',
	label_minimum_order_quantity_in_mt: 'Minimum Order Quantity (MOQ) in MT',
	label_opening_bid: 'Opening bid / MT',
	label_price_valid_till: 'Price valid till',
	label_publish_date: 'Publish Date',
	label_logistics_quotation_per_mt: 'Logistics Quotation / MT',
	label_lumpsum_logistics_quotation: 'Lumpsum Logistics Quotation',
	label_quote_mt: 'Quote / MT',
	label_select_from_existing_locations: 'Or, select from existing locations',
	label_select_validity_date: 'Select validity date',
	label_total_weight_to_be_sold: 'Total Weight (MT) to be sold',
	label_weight_mt: 'Weight (MT)',
	label_weight_mt_per_month: 'Weight (MT) per Month',
	label_your_bid_mt: 'Your bid / MT',
	logistic_ptnr_exists: 'Logistic partner already exists',
	logistic_invoice_add_success: 'Logistics invoice added successfully',
	upload_stock_photo_again: 'Upload Stock Photo again',
	your_order_name_sitename_po: 'Your Order name / Site Name',
	your_organisation_name: 'Your organisation name',

	cloud_factory: 'Cloud Factory',
	factories: 'Factories',
	add_new_factory: 'Add New Factory',
	edit_factory: 'Edit Factory',
	add_new_machine_template: 'Add Machine Template',
	edit_machine_template: 'Edit Machine Template',
	view_factory_inventory: 'View Factory Inventory',

	error_contact_support: 'Contact Support',
	error_something_went_wrong: 'Something went wrong. Please try again',
	error_email_exists: 'Email already exists. Please enter a different email address',
	error_wrong_password: 'Wrong password',
	error_user_found: 'Email address not found',
	err_status_not_allowed: 'Status change is not allowed',

	become_a_manufacturer_partner: 'Become a Manufacturer Partner',
	become_a_supplier_partner: 'Become a Supplier Partner',
	become_a_logistics_partner: 'Become a Logistics Partner',
	become_a_proccessing_partner: 'Become a Processing Partner',
	become_a_scrap_partner: 'Become a Scrap Partner',
	quantity: 'Quantity',
	batch_number: 'Batch Number',
	cast_number: 'Cast Number',
	uploaded_the_file_successfully: 'Uploaded the file successfully',
	document_upload_failed: 'Document upload failed.',
	manager: 'Manager',
	relationship_manager: 'Relationship Manager',
	account: 'Account',

	become_a_manufacturer_partner_description: 'Click here to become a manufacturing partner with Metalbook',
	become_a_manufacturer_partner_request_pending:
		'Your request to become a manufacturing partner is pending with Metalbook. You will get an update soon.',
	become_a_supplier_partner_description: 'Click here to become a supplier partner with Metalbook',
	become_a_supplier_partner_request_pending: 'Your request to become a supplier partner is pending with Metalbook',
	become_a_logistics_partner_description: 'Click here to become a logistics partner with Metalbook',
	become_a_logistics_partner_request_pending: 'Your request to become a logistics partner is pending with Metalbook. You will get an update soon.',
	become_a_proccessing_partner_description: 'Click here to become a processing partner with Metalbook',
	become_a_scrap_partner_description: 'Click here to become a scrap partner with Metalbook',
	become_a_proccessing_partner_request_pending:
		'Your request to become a processing partner is pending with Metalbook. You will get an update soon.',
	become_a_scrap_partner_request_pending: 'Your request to become a scrap partner is pending with Metalbook. You will get an update soon.',
	pls_make_address_r_colum: 'Please make sure the addresses are in A & B columns',
	request_role_change: 'Request role change',

	carousel_get_ahead:
		'Get ahead of market trends and price fluctuations with Metalbook and get real time insights and market news. Partnered with all major manufactures and suppliers across India to get you the best possible prices and deals.',
	carousel_guaranteed_time_delivery:
		'Guaranteed On time-delivery with Metalbook, real-time tracking facility so that you don’t have to worry about your delivery timelines.',
	carousel_get_access: 'Get Access to seamless Credit Line facility and explore flexible payment options with us to optimise your business.',
	carousel_helping_buyers: 'Helping buyers get instant quotes on their requirements and get them fulfilled through our verified suppliers',
	carousel_see_quotations:
		'See all quotations, compare prices, Manage all your Invoices, other related documents, ledger details and track your shipment in real time. Create Purchase orders and bills online with ease.',
	carousel_source_through:
		'Source through multiple verified Manufacturers & dealers dealing in multiple categories spread across the globe, fulfilled seamlessly through in-house logistics.',
	carousel_verified_network:
		'Verified network of 1000+ suppliers PAN India to help you get the best prices, quality and readily available stock of all the required materials.',
	carousel_view_product: 'View product catalogs , Generate RFQs, compare multiple quotations digitally.',

	supply_scrap: 'Supply Scrap',
	msg_failed_to_fetch_scrap_requirements: 'Failed to fetch scrap requirements. Please try reloading the page.',
	msg_scrap_quotation_submitted_successfully: 'Scrap quotation submitted successfully.',
	msg_scrap_quotation_submitted_failed: 'Failed to submit scrap quotation. Try again.',
	msg_account_added_success: 'Account details saved successfully',
	msg_account_added_failure: 'Account details not saved',
	msg_punch_order_created: 'Punch Order created',
	msg_punch_order_failure: 'Order estimate not created!',
	msg_punch_order_update_success: 'Punch Order Updated Successfully.',
	msg_punch_order_update_failure: 'Punch Order Updation Failed!!',

	msg_glossary_delete_success: 'Keyword deleted successfully',
	msg_glossary_delete_fail: 'Keyword not deleted!',
	msg_sure_want_to_delete_glossary: 'Are you sure you want to delete the word?',
	msg_glossary_added_success: 'Keyword added to glossary successfully',
	msg_glossary_added_failed: 'Keyword not added!',
	msg_edit_glossary_success: 'Keyword edited successfully',
	msg_edit_glossary_failure: 'Keyword not edited!',

	demand_at: 'Demand at',
	per_month: 'per Month',
	type_of_scrap: 'Type of Scrap',
	your_quote: 'Your Quote',
	your_monthly_receivables: 'Your monthly receivables',
	proceed: 'Proceed',
	where_can_you_supply: 'Where can you supply?',
	quote_now: 'Quote Now',
	select_available_scrap_category: 'Add another SCRAP Type',
	orgs_and_users: 'Organisations & Admins',

	notification_email: 'Email',
	notification_platform: 'Platform (Desktop / Mobile)',
	notification_sms: 'SMS',
	notification_whatsapp: 'WhatsApp',

	collection_customer_detail_header: 'Who are we connected to in the customer?. ',
	collection_meeting_schedule_header: 'Meeting scheduled with Mbook Management/Risk head. ',
	collection_add_remark_header: 'Any other remarks? 	',
	lead_status_change: 'Confirm to change status',
	leads_change_manager_confirm: 'Confirm to change the manager',
	lead_deleted_success: 'Lead deleted successfully',
	lead_deleted_failure: 'Lead is not deleted',

	total_revenue_def:
		'Total Revenue is the total income of a business and is calculated by multiplying the quantity of goods sold by the price of the goods.',
	clv_def: 'Customer lifetime value (CLV) is the total revenue you expect from an individual customer over a span of time.',
	ccr_def: 'Lead to order conversion rate (lead to CCR) is total number of qualified leads that results in sales per total number of leads.',
	activation_rate_def: 'Activation rate measures the number of users who have taken an action over a specified period of time.',
	ror_def: 'Repeat order ratio (ROR) measures the percentage of transactions that are repeat purchases.',
	arpa_def:
		'The average revenue per account (ARPA) determines the revenue-generating capacity of the marketplace at an account level over a specific period of time.',
	buyer_to_seller_ratio_def: 'The buyer-to-seller (or provider-to-customer) ratio measures how many customers one vendor can serve.',
}

export default english
